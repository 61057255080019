<script setup lang="ts">
import airpazFeatures from '~/config/airpazFeatures';

interface IProps {
  type?: string[];
}

const props = defineProps<IProps>();

const airpazFeaturesList = computed(() => airpazFeatures(...(props.type ?? 'all')));
</script>

<template>
  <h2 class="font-bold text-gray-darkest text-extra mb-40 text-center">{{ $t('global.whyairpazheadline') }}</h2>

  <div class="flex justify-between gap-x-[55px]">
    <div
      v-for="(feature, index) in airpazFeaturesList"
      :key="index"
      class="flex flex-col flex-1 gap-15 items-center h-full"
    >
      <div class="w-[100px] h-[100px]">
        <NuxtImg
          :src="`/img/illustration/v2/spot/${feature.icon}.png`"
          :alt="$t(feature.title)"
          format="webp"
          width="100"
          height="100"
          loading="lazy"
          sizes="lg:200px"
          class="w-full h-full"
        />
      </div>

      <h3 class="font-bold text-gray-darkest text-center">
        {{ $t(feature.title) }}
      </h3>

      <p class="text-gray-dark text-center">
        {{ $t(feature.description) }}
      </p>
    </div>
  </div>
</template>
